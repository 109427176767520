import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Participant } from "../../components/Participant";
import { Button } from "../../components/Button";
import { ChatBox } from "../../components/Chat";
import { InviteParticipant } from "../../components/InviteParticipant";
import { ToggleAudioButton } from "../../components/ToggleAudioButton";
import { TogglePauseButton } from "../../components/TogglePauseButton";
import { ToggleVideoButton } from "../../components/ToggleVideoButton";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { clearSS, getProtocolId } from "../../sessionstorage";
import {
  finalizeRoomByAttendant,
  attendantUpdateStatus,
  finalizeRoomByVisitant,
  verifyPausedRoom,
} from "../../socket";
import sweetAlert from "../../utils/sweetAlert";
import { SideBar } from "../../components/SideBar";
import { Participant as ParticipantTwilio } from "twilio-video";
import {
  ButtonsContainerBottom,
  ContactAgainContainer,
  InviteParticipantButton,
  RoomContainer,
  VideoContainer,
  VideoContent,
  LocalVideoArea,
  GuestVideoArea,
  VisitantVideoArea,
  VideoDisabledIconContainer,
  TimerView,
  TimerIcon,
  StatusText,
  TabulationHeading,
  TimeContainer,
  Logo,
  AttendantPaused,
  NameContainer,
} from "./styles";
import Callvideologo from "../../assets/pessoalize/callvideoLoginLogo.png";
import IconChat from "../../assets/images/chat.svg";

import { CamOffIconButton } from "../../assets/icons";
import { useAuth } from "../../hooks/auth";
import Timer from "react-compound-timer/build";
import { FormatNumber } from "../../utils/FormatNumber";
import dayjs from "dayjs";
import EndCallIcon from "../../assets/images/end-call.svg";

export const Room: React.FC = () => {
  const { videoTrack, participants } = useRoomConnect();
  const [openChat, setOpenChat] = useState<Boolean>(false);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [tabbingFields, setTabbingFields] = useState<any>();
  const [dynamicFields, setDynamicFields] = useState<any>({});
  const [arrayTabbingFields] = useState<Array<any>>([]);
  const [roomAssets, setRoomAssets] = useState<any>();
  const [loaderButton, setLoaderButton] = useState<boolean>(false);
  const [tabbingError, setTabbingError] = useState<string | undefined>();
  const [showContactAgain, setShowContactAgain] = useState<boolean>(false);
  const [attendantIsPaused, setAttendantIsPaused] = useState<
    boolean | undefined
  >(undefined);
  const [queryParams, setQueryParams] = useState<any>();
  const [showInviteParticipant, setShowInviteParticipant] =
    useState<boolean>(false);
  const [inviteParticipantPassword, setInviteParticipantPassword] =
    useState<string>("");
  const [participantIsVisitant, setParticipantIsVisitant] =
    useState<ParticipantTwilio>();
  const [participantIsGuest, setParticipantIsGuest] =
    useState<ParticipantTwilio>();
  const localVideoRef = useRef<any>();

  const params: { protocolId: string } = useParams();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [contactAgain, setContactAgain] = useState<string | undefined>(
    undefined
  );
  const [videoDisabled, setVideoDisabled] = useState(false);
  const [finalizeCall, setFinalizeCall] = useState<boolean>(false);
  const [finalizeByVisitant, setFinalizeVisitant] = useState<boolean>(false);
  const guestAreaRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const visitantAreaRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const [allOption, setAllOption] = useState(null);

  const [tabbingTimer, setTabbingTimer] = useState<number>(0);
  const [callTimer, setCallTimer] = useState<number>(0);

  const getVisitantQuery = useCallback(async () => {
    const query = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/visitant/queryparams/${params.protocolId}`,
    });
    if (query) {
      setQueryParams(query);
    }
    // eslint-disable-next-line
  }, []);

  const verifyRequiredField = () => {
    if (tabbingFields?.reason || !allOption) {
      setTabbingError(undefined);
      return true;
    } else {
      setTabbingError("Preencher campos obrigatórios");
      return false;
    }
  };

  const handleFinalizeOfensiveCall = async () => {
    setFinalizeCall(true);
    finalizeRoomByAttendant(
      {
        protocolId: params.protocolId,
        tabbingFields: arrayTabbingFields,
        contactAgainMessage: contactAgain,
      },
      () => {
        clearSS();
        window.location.href = "/";
      }
    );
  };

  const handleFinalizeButton = async (contactAgainOption?: string) => {
    setContactAgain(contactAgainOption);
    setFinalizeCall(true);
    if (verifyRequiredField()) {
      arrayTabbingFields.push(tabbingFields);
      const dynamicFieldsArray = [];

      if (Object.keys(dynamicFields).length > 0) {
        for (const field in dynamicFields) {
          dynamicFieldsArray.push({
            label: field,
            value: dynamicFields[field],
          })
        }
      }

      arrayTabbingFields.push(dynamicFieldsArray);

      const alert = await sweetAlert.confirm(
        "Deseja finalizar a chamada?",
        "Confirmar",
        "Permanecer na chamada"
      );
      setShowSidebar(false);

      if (alert) {
        setLoaderButton(true);
        finalizeRoomByAttendant(
          {
            protocolId: params.protocolId,
            tabbingFields: arrayTabbingFields,
            contactAgainMessage: contactAgain,
          },
          () => {
            clearSS();
            window.location.href = "/";
          }
        );
      } else {
        setFinalizeCall(false);
        setShowSidebar(true);
        setTabbingFields({});
      }
    }
  };

  const getPageAssets = useCallback(async () => {
    const assets = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/attendant/${params.protocolId}`,
    });

    if (assets) {
      const today = dayjs();
      const eventDate = assets.statusList.pop().createdAt;
      const diff = today.diff(dayjs(eventDate));

      if (assets.lastStatus === "VISITANT_HANDLE_EXIT_ROOM") {
        setTabbingTimer(diff);
        setFinalizeVisitant(true);
        attendantUpdateStatus("Tabulando");
      } else {
        setCallTimer(diff);
        attendantUpdateStatus("Em atendimento");
        verifyPausedRoom(getProtocolId() || "", (pausedRoomResponse: any) => {
          setAttendantIsPaused(pausedRoomResponse.roomIsPaused);
        });
      }

      setRoomAssets(assets);
    }
  }, []);

  const handleInviteParticipantButton = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/generate-invite-pass/${params.protocolId}`,
    });
    if (!response.error) {
      setInviteParticipantPassword(response.pass);
      setShowInviteParticipant(true);
    }
  };

  const getFieldsTabbing = async (clientId: string) => {
    try {
      const query = await request({
        showLoading: true,
        showSuccessMessage: false,
        showErrorMessage: false,
        method: "POST",
        path: `client/getfield`,
        data: { clientId, field: "tabbing" },
      });
      setAllOption(query);
    } catch (error) {
      setAllOption(null);
    }
  };
  useEffect(() => {
    getPageAssets();
    getVisitantQuery();
    finalizeRoomByVisitant(() => setFinalizeVisitant(true));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (videoTrack && videoTrack.name) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;

      videoTrack.on("enabled", () => setVideoDisabled(false));
      videoTrack.on("disabled", () => setVideoDisabled(true));
      setVideoDisabled(!videoTrack.isEnabled);

      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [localVideoRef]); //eslint-disable-line

  useEffect(() => {
    participants.forEach((p) => {
      if (p.identity.includes("guest")) setParticipantIsGuest(p);
      else if (!p.identity.includes("guest") && !!participantIsGuest)
        setParticipantIsGuest(undefined);
      if (p.identity.includes("visitant")) setParticipantIsVisitant(p);
    });
  }, [participantIsGuest, participants]);

  useEffect(() => {
    if (roomAssets) {
      getFieldsTabbing(roomAssets.clientId);
    }
  }, [roomAssets]);

  useEffect(() => {
    if (openChat) {
      setMenuIsOpen(true);
    }
  }, [openChat]);

  useEffect(() => {
    if (!menuIsOpen) {
      setOpenChat(false);
    }
  }, [menuIsOpen]);

  return (
    <RoomContainer
      bgColor={roomAssets?.backgroundColor || "#2c1050"}
      contrastColor={roomAssets?.contrastColor || "#0a070f"}
    >
      {!finalizeByVisitant && showInviteParticipant && roomAssets && (
        <InviteParticipant
          roomAssets={roomAssets}
          clientLink={roomAssets.inviteParticipantLink}
          password={inviteParticipantPassword}
          setShow={setShowInviteParticipant}
        ></InviteParticipant>
      )}

      {showContactAgain && roomAssets && roomAssets.contactAgainMessage && (
        <ContactAgainContainer>
          <p>{roomAssets.contactAgainMessage}</p>
          <div>
            <button
              data-cy="btnContactAgainYes"
              onClick={async () => {
                setShowContactAgain(false);
                await handleFinalizeButton("Sim");
              }}
              style={{
                backgroundColor: roomAssets?.buttonColor,
                color: roomAssets?.textColor,
              }}
            >
              Sim
            </button>
            <button
              data-cy="btnContactAgainNot"
              onClick={async () => {
                setShowContactAgain(false);
                await handleFinalizeButton("Não");
              }}
              style={{
                backgroundColor: roomAssets?.buttonColor,
                color: roomAssets?.textColor,
              }}
            >
              Não
            </button>
            <button
              data-cy="btnContactAgainUninformed"
              onClick={async () => {
                setShowContactAgain(false);
                await handleFinalizeButton("Não informado");
              }}
              style={{
                backgroundColor: roomAssets?.buttonColor,
                color: roomAssets?.textColor,
              }}
            >
              Não informado
            </button>
          </div>
        </ContactAgainContainer>
      )}
      {!finalizeByVisitant && (
        <VideoContainer menuIsOpen={menuIsOpen} id="video-containers">
          <NameContainer textColor={roomAssets?.textColor}>
            <p>Atendendo:</p>
            <h3>{roomAssets?.nameFull}</h3>
          </NameContainer>
          <div className="container-video">
            <VideoContent>
              <LocalVideoArea>
                {videoDisabled && localVideoRef ? (
                  <VideoDisabledIconContainer
                    props={{
                      width: localVideoRef.current.clientWidth,
                      height: localVideoRef.current.clientHeight,
                    }}
                  >
                    <CamOffIconButton />
                  </VideoDisabledIconContainer>
                ) : (
                  <></>
                )}

                <video
                  ref={localVideoRef}
                  autoPlay={true}
                  style={{ display: videoDisabled ? "none" : "block" }}
                />
              </LocalVideoArea>
              <GuestVideoArea ref={guestAreaRef}>
                {participantIsGuest && (
                  <Participant
                    key={participantIsGuest.sid}
                    participant={participantIsGuest}
                    clientLogo={roomAssets ? roomAssets.logo : false}
                    isGuest={true}
                    areaRef={guestAreaRef}
                    finalizeCall={finalizeCall}
                  />
                )}
              </GuestVideoArea>
              <VisitantVideoArea
                ref={visitantAreaRef}
                useMobile={
                  participantIsVisitant &&
                  participantIsVisitant.identity.indexOf("mobile") > -1
                    ? true
                    : false
                }
              >
                {attendantIsPaused && (
                  <AttendantPaused>
                    <div className="waiting">
                      <StatusText>Atendimento Pausado</StatusText>

                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </AttendantPaused>
                )}
                {participantIsVisitant && (
                  <Participant
                    isVisitant={true}
                    areaRef={visitantAreaRef}
                    key={participantIsVisitant.sid}
                    participant={participantIsVisitant}
                    clientLogo={roomAssets ? roomAssets.logo : false}
                    finalizeByVisitant={finalizeByVisitant}
                    finalizeCall={finalizeCall}
                    showLogo={false}
                  />
                )}
              </VisitantVideoArea>

              <ButtonsContainerBottom>
                {/* <button
                    style={{
                      backgroundColor: roomAssets?.buttonColor,                    
                    }}
                    data-cy="btnEndOfensive"
                    className="btn-end-ofensive"                    
                    onClick={handleFinalizeOfensiveCall}
                >
                  <img src={EndCallIcon} />
                </button>            */}
                <TogglePauseButton
                  data-cy="togglePauseBtn"
                  switched={(pauseEnable: boolean) => {
                    setAttendantIsPaused(pauseEnable);
                  }}
                  roomAssets={roomAssets}
                />
                <InviteParticipantButton
                  data-cy="btnInvite"
                  onClick={handleInviteParticipantButton}
                  style={{
                    backgroundColor: roomAssets?.buttonColor,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 125"
                    enableBackground="new 0 0 100 100"
                  >
                    <path
                      fill={roomAssets?.iconColor || "#5c27b3"}
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M81.757,36.811h-4.154v4.154c0,1.689-1.369,3.058-3.058,3.058h-1.139  c-1.689,0-3.058-1.369-3.058-3.058v-4.154h-4.154c-1.689,0-3.058-1.369-3.058-3.058v-1.138c0-1.689,1.369-3.058,3.058-3.058h4.154  v-4.154c0-1.689,1.369-3.058,3.058-3.058h1.139c1.689,0,3.058,1.369,3.058,3.058v4.154h4.154c1.689,0,3.058,1.369,3.058,3.058v1.138  C84.815,35.441,83.446,36.811,81.757,36.811z M72.614,66.916c0.078,0.347,0.129,0.705,0.129,1.077v8.4c0,2.651-2.102,4.8-4.693,4.8  H19.878c-2.592,0-4.693-2.149-4.693-4.8v-8.4c0-0.352,0.042-0.693,0.113-1.024c0.722-10.405,8.713-19.122,18.475-22.573  c-3.274-2.637-5.373-6.673-5.373-11.204c0-7.945,6.44-14.386,14.385-14.386S57.17,25.248,57.17,33.193  c0,4.496-2.064,8.507-5.295,11.145C62.316,47.74,71.771,56.474,72.614,66.916z M22.418,67.744c-0.012,0.071-0.042,0.136-0.042,0.21  v4.659c0,0.745,0.604,1.349,1.349,1.349h3.45v-6.503c0-1.651,1.338-2.989,2.989-2.989h1.2c1.651,0,2.989,1.338,2.989,2.989v6.503  h19.222v-6.503c0-1.651,1.338-2.989,2.989-2.989h1.2c1.651,0,2.989,1.338,2.989,2.989v6.503h3.445c0.745,0,1.349-0.604,1.349-1.349  v-4.659c0-0.066-0.029-0.123-0.038-0.187C65.097,57.905,53.283,50.01,43.056,50.01C32.837,50.01,22.817,57.893,22.418,67.744z   M42.785,25.95c-4,0-7.243,3.243-7.243,7.243c0,4,3.243,7.243,7.243,7.243s7.243-3.243,7.243-7.243  C50.027,29.193,46.785,25.95,42.785,25.95z"
                    />
                  </svg>
                </InviteParticipantButton>

                {roomAssets?.showChat ? (
                  <Button
                    style={{
                      backgroundColor: roomAssets?.buttonColor,
                    }}
                    data-cy="btnChat"
                    className={openChat ? "open-chat chatOpened" : "open-chat"}
                    text={              
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.3278 3.98779H3.89541C3.59952 3.98779 3.35938 4.22794 3.35938 4.52382C3.35938 4.81971 3.59952 5.05986 3.89541 5.05986H10.3278C10.6237 5.05986 10.8638 4.81971 10.8638 4.52382C10.8638 4.22794 10.6237 3.98779 10.3278 3.98779Z" fill={roomAssets?.iconColor || "#6229BF" } />
                        <path d="M8.18366 6.13281H3.89541C3.59952 6.13281 3.35938 6.37295 3.35938 6.66884C3.35938 6.96473 3.59952 7.20488 3.89541 7.20488H8.18366C8.47955 7.20488 8.71969 6.96473 8.71969 6.66884C8.71969 6.37295 8.47955 6.13281 8.18366 6.13281Z" fill={roomAssets?.iconColor || "#6229BF" }/>
                        <path d="M11.9372 0.772461H2.28866C1.10617 0.772461 0.144531 1.7341 0.144531 2.91659V13.6372C0.144531 13.8452 0.264602 14.035 0.453285 14.1229C0.525114 14.1561 0.603374 14.1732 0.680563 14.1732C0.80385 14.1732 0.926065 14.1304 1.02362 14.0489L4.09079 11.4931H11.9372C13.1197 11.4931 14.0813 10.5315 14.0813 9.34896V2.91659C14.0813 1.7341 13.1197 0.772461 11.9372 0.772461ZM13.0093 9.34896C13.0093 9.93967 12.529 10.421 11.9372 10.421H3.89675C3.77132 10.421 3.65018 10.465 3.55369 10.5454L1.21659 12.4933V2.91659C1.21659 2.32588 1.69688 1.84452 2.28866 1.84452H11.9372C12.529 1.84452 13.0093 2.32588 13.0093 2.91659V9.34896Z" fill={roomAssets?.iconColor || "#6229BF" } />
                        <path d="M15.1542 5.06055C14.8583 5.06055 14.6182 5.30069 14.6182 5.59658C14.6182 5.89247 14.8583 6.13261 15.1542 6.13261C15.746 6.13261 16.2263 6.61397 16.2263 7.20467V16.2733L14.4166 14.826C14.3223 14.7509 14.2033 14.7091 14.0822 14.7091H6.57772C5.98594 14.7091 5.50566 14.2278 5.50566 13.637V13.101C5.50566 12.8051 5.26551 12.565 4.96963 12.565C4.67374 12.565 4.43359 12.8051 4.43359 13.101V13.637C4.43359 14.8195 5.39523 15.7812 6.57772 15.7812H13.8935L16.4268 17.8084C16.5243 17.8856 16.6433 17.9253 16.7623 17.9253C16.8406 17.9253 16.9199 17.9081 16.995 17.8728C17.1804 17.7827 17.2983 17.5951 17.2983 17.3893V7.20467C17.2983 6.02219 16.3367 5.06055 15.1542 5.06055Z" fill={roomAssets?.iconColor || "#6229BF" } />
                      </svg>
                    }
                    onClick={() => setOpenChat(!openChat)}
                  >
                  </Button>
                ) : null}

                <ToggleAudioButton
                  roomAssets={roomAssets}
                  type="circle"
                  pauseRoom={false}
                />
                <ToggleVideoButton
                  roomAssets={roomAssets}
                  type="circle"
                  pauseRoom={false}
                />
                <TimerView
                  style={{
                    backgroundColor: roomAssets?.buttonColor,
                    color: roomAssets?.iconColor,
                  }}
                >
                  <TimerIcon
                    bgColor={roomAssets?.buttonColor}
                    txtColor={roomAssets?.iconColor}
                  />
                  <p>
                    {callTimer ? (
                      <Timer
                        formatValue={FormatNumber.addZero}
                        initialTime={callTimer}
                      >
                        <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                      </Timer>
                    ) : null}
                  </p>
                </TimerView>
              </ButtonsContainerBottom>
            </VideoContent>
            <ChatBox
              className={openChat ? "chatOpen" : ""}
              toggleChat={openChat}
              setToggleChat={setOpenChat}
              roomAssets={roomAssets}
            />
          </div>
        </VideoContainer>
      )}

      {finalizeByVisitant && (
        <TabulationHeading>
          <div className="waiting">
            <StatusText>Tabulando</StatusText>

            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <TimeContainer>
            <Timer
              formatValue={FormatNumber.addZero}
              initialTime={tabbingTimer}
            >
              <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
            </Timer>
          </TimeContainer>
        </TabulationHeading>
      )}

      {showSidebar ? (
        <SideBar
          handleFinalizeButton={handleFinalizeButton}
          loaderButton={loaderButton}
          menuIsOpen={menuIsOpen}
          queryParams={queryParams}
          roomAssets={roomAssets}
          setMenuIsOpen={setMenuIsOpen}
          setShowContactAgain={setShowContactAgain}
          setTabbingFields={setTabbingFields}
          setDynamicFields={setDynamicFields}
          tabbingError={tabbingError}
          tabbingFields={tabbingFields}
          contactAgain={contactAgain}
          allOption={allOption}
        />
      ) : (
        <></>
      )}
      {finalizeByVisitant && <Logo src={Callvideologo} alt="Pessoalize" />}
    </RoomContainer>
  );
};
