import { useState } from "react";
import callvideoLogo from "../../assets/pessoalize/callvideoLoginLogo.png";
import { BreakMask } from "./styles";
import { request } from "../../services/request";
import Timer from "react-compound-timer";
import { getIpData } from "../../utils";
import { socket } from "../../socket";
import { FormatNumber } from "../../utils/FormatNumber";

type BreakProps = {
  setBreakTime: React.Dispatch<
    React.SetStateAction<{
      value: string;
      label: string;
    }>
  >;
};

export const BreakScreen: React.FC<BreakProps> = ({
  setBreakTime,
}: BreakProps) => {
  const [password, setPassword] = useState<string>();

  const handleBreakExit = async () => {
    let response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/remove-break`,
      data: {
        ip: await getIpData(),
        socket: socket.id,
        pass: password,
      },
    });

    if (!response.error) {
      setBreakTime({ value: "", label: "" });
    }
  };

  return (
    <BreakMask>
      <img className="pessoalize-logo" src={callvideoLogo} alt="Pessoalize" />
      <h2>Em pausa</h2>
      <Timer formatValue={FormatNumber.addZero}>
        <h3>
          <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
        </h3>
      </Timer>
      <div>
        <input
          placeholder="Digite sua senha"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          data-cy="passwordBreak"
        />
        <button data-cy="btnStopBreak" onClick={handleBreakExit}>
          Sair da pausa
        </button>
      </div>
    </BreakMask>
  );
};
